import React, {Component} from 'react';
import BusinessInsightCard from "./BusinessInsightCard";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class BusinessInsightCardList extends Component {
    state = {
        cardData : [],
        isMobile : deviceDetect()
    };

    render() {

        const cardTag = this.state.cardData.map((card, index) => <BusinessInsightCard key={index} title={card.title} body={card.body} logos={card.logos} />);

        if (this.state.isMobile) {
            return (
                <div className="businessCardList_mobile">
                    {cardTag}
                </div>
            )
        }

        return (
            <div className="businessCardList">
                {cardTag}
            </div>
        );
    }

    getCardData = () => {
        const cards = [
            {
                title : '금융',
                body : [
                    '인공지능 시세를 이용한 소형 공동주택 담보대출',
                    '담보대출 업무 프로세스 자동화 (API)'
                ],
                logos : [
                    require('../../../../static/assets/components/businessInsight/금융/logo_nh투자증권.png'),
                    // require('../../../../static/assets/components/businessInsight/금융/logo_papper.png'),
                    require('../../../../static/assets/components/businessInsight/금융/logo_농협중앙회.png'),
                    require('../../../../static/assets/components/businessInsight/금융/logo_신한은행-1.png'),
                    require('../../../../static/assets/components/businessInsight/금융/logo_hana.png'),
                    require('../../../../static/assets/components/businessInsight/금융/logo_하나카드.png')
                ]
            },
            {
                title : '유통/공공',
                body : [
                    '점포 개발 예상 매출액 시뮬레이션',
                    '지역 상권 변화 모니터링',
                    '지역 위험 모니터링 및 예측'
                ],
                logos : [
                    // require('../../../../static/assets/components/businessInsight/유통/logo_alvolo.png'),
                    require('../../../../static/assets/components/businessInsight/유통/logo_농림축산검역본부.png'),
                    require('../../../../static/assets/components/businessInsight/유통/logo_서울특별시.png')
                ]
            },
            {
                title : 'IT',
                body : [
                    '빅밸류 공간DB를 이용한 서비스 개발 시간 단축',
                    '부동산 정기 데이터를 통한 고객 Push 서비스'
                ],
                logos : [
                    require('../../../../static/assets/components/businessInsight/IT/logo_refine.png'),
                    require('../../../../static/assets/components/businessInsight/IT/logo_trustay.png'),
                    require('../../../../static/assets/components/businessInsight/IT/logo_wonlab.png'),
                    require('../../../../static/assets/components/businessInsight/IT/logo_뱅크샐러드.png'),
                    // require('../../../../static/assets/components/businessInsight/IT/logo_삼성sds.png')
                ]
            },
            {
                title : '도시계획/건설',
                body : [
                    '최적 개발 사업부지 탐색',
                    '개략 사업성 평가(분양가 산정 및 인허가성 분석)',
                    '요구 조건 입지분석 및 기준 마련'
                ],
                logos : [
                    // require('../../../../static/assets/components/businessInsight/도시계획/logo_DL.png'),
                    require('../../../../static/assets/components/businessInsight/도시계획/logo_SKecoplant.png')
                ]
            }
        ]

        this.setState({
            cardData : cards
        });
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getCardData();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default BusinessInsightCardList;