import React, {useEffect, useState} from "react";
import styles from './index.module.css';
import {CopyToClipboard} from "react-copy-to-clipboard";
import logo from "./assets/footer2.png"
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";
import noImg from "../../static/assets/utils/noimg.png";

function Footer(props) {

    const [isMobile, setMobile] = useState(deviceDetect());

    const link = (url) => {
        window.location = url;
    }

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    if (isMobile) {
        return (
            <div className={styles.bkgnd_mb}>
                <div className={styles.location_mb}>
                    <div className={styles.company_mb}>주식회사 빅밸류</div>

                    <div className={styles.address_mb}>
                        <CopyToClipboard className={styles.contact_copy_text} text="서울특별시 중구 서소문로 138 대한일보빌딩 12층" onCopy={() => alert("클립보드에 복사되었습니다.")}>
                            <span>서울특별시 중구 서소문로 138 대한일보빌딩 12층</span>
                        </CopyToClipboard>
                        <div>
                            <button className={styles.button_mb} onClick={()=>link('/CompanyLocation')}>  지도보기 > </button>
                        </div>
                        <div>대표전화: <CopyToClipboard className={styles.contact_copy_text} text="070.7762.9364" onCopy={() => alert("클립보드에 복사되었습니다.")}><span>070.7762.9364</span></CopyToClipboard></div>
                    </div>
                </div>
                <div className={styles.copyright_mb}>
                    <div>Copyright 2023 by BIGVALUE CO.LTD. All rights reserved</div>
                    <div className={styles.support_mb}>
                        <img src={logo} alt="logo" onError={(e) => e.target.src = `${noImg}`}/>
                        <div>혁신금융서비스사업자 선정기업</div>
                    </div>
                </div>
            </div>
        );
    }

    return(<div className={styles.bkgnd}>

        <div className={styles.content}>

            <div className={styles.location}>
                <div className={styles.company}>주식회사 빅밸류</div>

                <div className={styles.address}>
                    <div>
                        <CopyToClipboard className={styles.contact_copy_text} text="서울특별시 중구 서소문로 138 대한일보빌딩 12층" onCopy={() => alert("클립보드에 복사되었습니다.")}>
                            <span>서울특별시 중구 서소문로 138 대한일보빌딩 12층</span>
                        </CopyToClipboard>
                        <button className={styles.button} onClick={()=>link('/CompanyLocation')}>  지도보기 > </button>
                    </div>
                    <div>대표전화: <CopyToClipboard className={styles.contact_copy_text} text="070.7762.9364" onCopy={() => alert("클립보드에 복사되었습니다.")}><span>070.7762.9364</span></CopyToClipboard></div>
                </div>
            </div>

            <div className={styles.sitemap}>
                <div className={styles.sitemapCategory}>
                    <div className={styles.sitemapHead}>Data</div>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/dataMap')} >Data Map</div>
                </div>

                <div className={styles.sitemapCategory}>
                    <div className={styles.sitemapHead}>Solution</div>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/solution/al')}>AI LOBIG</div>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/solution/ad')}>AI Developer</div>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/solution/va')}>V-Advisor</div>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/solution/vi')}>Villasise.com</div>
                </div>

                <div className={styles.sitemapCategory}>
                    <div className={styles.sitemapHead}>Resource</div>
                    {/*<div className={styles.sitemapSub} onClick={()=>link('/resources/sw')} >Seminar·Webinar</div>*/}
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/resources/pr')}>Press Release </div>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/resources/bv')}>BV Contents</div>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/resources/media')}>Media </div>
                    {/*<div className={styles.sitemapSub} onClick={()=>link('/resources/cs')}>Case studies </div>*/}
                </div>

                <div className={styles.sitemapCategory} >
                    <div className={styles.sitemapHead} >About us</div>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/about')}>About us</div>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/about/vision')}>Vision</div>
                    <a className={styles.sitemapSub} tabIndex={-1} href='https://recruit.bigvalue.co.kr/' target="_blank">Careers</a>
                    <div className={styles.sitemapSub} tabIndex={-1} onClick={()=>link('/about/contactUs')}>Contact us</div>
                </div>

            </div>

        </div>

        <div className={styles.copyright}>
            <div>Copyright 2023 by BIGVALUE CO.LTD. All rights reserved</div>

            <div className={styles.support}>
                <div>혁신금융서비스사업자 선정기업</div>
            </div>
        </div>

    </div>);
}

export default Footer;