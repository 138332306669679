import React, {useEffect, useRef, useState} from "react";
import styles from './index.module.css';
import axios from "axios";
import PrivacyStatement from "./privacyStatement";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

function ContactUs() {

  const [params, setParams] = useState({
    type: 3,
    contactName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
  });
  const [checked, setChecked] = useState(false);
  const [count, setCount] = useState(21);
  const [downList, setDownList] = useState([]);
  const [isMobile, setMobile] = useState(deviceDetect());

  const patternPhone = /^\d{2,3}[-|' ']?\d{3,4}[-|' ']?\d{4}$/;
  const patternEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

  const nameInput = useRef();
  const emailInput = useRef();
  const phoneNumberInput = useRef();
  const companyNameInput = useRef();
  const inquiryInput = useRef();
  const checkInput = useRef();

  useEffect(() => {
    const windowResize = () => {
      const res = deviceDetect();

      setMobile(res);
    }

    window.addEventListener(`resize`, windowResize);

    return () => {
      window.removeEventListener(`resize`, windowResize);
    };
  }, [])

  useEffect(() => {

  })

  const save = () => {

    if (!params.email) {
      alert('이메일을 입력해주세요.');
      emailInput.current.focus();
      return;
    }

    if (!patternEmail.test(params.email)) {
      alert('이메일을 확인해주세요.');
      emailInput.current.focus();
      return;
    }

    if (params.phoneNumber != null || params.phoneNumber !== undefined) {
      if (params.phoneNumber != '') {
        if (!patternPhone.test(params.phoneNumber)) {
          alert('전화번호를 확인해주세요.');
          phoneNumberInput.current.focus();
          return;
        }
      }
    }

    // if (!params.companyName)
    // {
    //     alert('회사명을 입력해주세요.');
    //     companyNameInput.current.focus();
    //     return;
    // }

    if (!params.inquiry) {
      alert('문의내용을 입력해주세요.');
      inquiryInput.current.focus();
      return;
    }

    if (!checked) {
      alert('개인정보 제공 동의를 체크해주세요.');
      return;
    }

    if (params["type"] != 1) {
      let copy = params;
      copy['downItems'] = null;
      setParams(copy);
    }

    let formData = new FormData();

    formData.append("type", params["type"]);
    formData.append("contactName", params["contactName"]);
    formData.append("email", params["email"]);
    formData.append("inquiry", params["inquiry"]);
    formData.append("phoneNumber", params["phoneNumber"]);
    formData.append("companyName", params["companyName"]);
    formData.append("downItems", params["downItems"])

    axios.post(process.env.REACT_APP_API_URL + '/api/contact/create', formData)
    .then((response) => {
      if (response.data == '1') {
        alert('문의내용이 등록되었습니다. 감사합니다.');

        if (params["type"] == 1 && params["downItems"].length > 0) {
          axios.post(process.env.REACT_APP_API_URL + '/api/contact/mailSend', formData);
        }
        //이전 페이지 이동
        const referrer = document.referrer;

        if (referrer) {
          window.location = referrer;
        }
        else {
          window.location = "/";
        }
      }
      else {
        alert('문의내용이 등록되지 않았습니다. 재입력 바랍니다.');
      }
    });
  }

  const onChange = (e) => {

    const {name, value} = e.target;
    let copy = {...params};

    copy[name] = value;
    setParams(copy);
  }

  const onChange2 = (e) => {

    const {checked} = e.target;

    setChecked(checked);
  }

  function changeSelect(e) {
    const {name, value} = e.target;
    let textValue = document.getElementById("text_input_area");
    if (value == 1) {
      textValue.value = "해당 솔루션의 소개 자료 부탁드립니다.";
    }
    else if (value == 2) {
      textValue.value = "구매 가능한 데이터가 정리된 데이터 카달로그 요청 드립니다.";
    }
    else if (value == 3) {
      textValue.value = "업무 협약 등 기타 문의입니다.";
    }
    let copy = {...params};

    copy[name] = value;
    setParams(copy);
  }

  function changeFormPhone(e) {
    const {name, value} = e.target;
    const num = value.replace(/[^0-9]/g, "");
    const front = num.slice(0, 2) === '02' ? 2 : 3;
    const len = num.slice(0, 2) === '02' ? 10 : 11;
    const back = num.length < len ? front + 3 : front + 4;
    let copy = {...params};

    if (num.length <= 3) {
      e.target.value = num;
    }
    else if (num.length < 6) {
      e.target.value = num.slice(0, front) + '-' + num.slice(front);
    }
    else {
      e.target.value = num.slice(0, front) + '-' + num.slice(front, back) + '-' + num.slice(back);
    }

    copy[name] = e.target.value;
    setParams(copy);
  }

  function inquiryControl(e) {
    const {name, value} = e.target;
    const len = value.length;
    let copy = {...params};

    if (value.length > 1000) {
      e.target.value = value.slice(0, 1000);
      setCount(1000);
    }
    else {
      setCount(len);
    }

    copy[name] = value;
    setParams(copy);
  }

  function changeCheck(e) {
    e.target.parentElement.children[0].checked = !e.target.parentElement.children[0].checked
  }

  function checkReport(e) {
    const isCheck = e.target.parentElement.children[0].checked;
    const value = e.target.parentElement.children[0].value;
    let copy = downList;
    let copyParam = params;

    if (isCheck) {
      copy.push(value)
    }
    else {
      copy = copy.filter(id => id != value)
    }

    setDownList(copy);

    let download = downList[0];

    for (let i = 1; i < downList.length; i++) {
      download += "," + downList[i];
    }

    copyParam["downItems"] = download;
    setParams(copyParam);
  }

  useEffect(() => {
    let group = isMobile ? window.document.getElementById('checkGroup_mb') : window.document.getElementById('checkGroup');

    if (params['type'] == 1) {
      group.className = isMobile ? styles.checkGroup_mb : styles.checkGroup;
    }
    else {
      group.className = (isMobile ? styles.checkGroup_mb : styles.checkGroup) + " " + styles.hide;
    }
  }, [params['type']]);

  function saveCheckParams(e) {

    const {checked} = e.target.parentElement.children[0];

    setChecked(checked);
  }

  if (isMobile) {
    return (
      <div className={styles.background_mb}>
        <div className={styles.contact_header_mb}>
          <div className={styles.title_mb}>Contact us</div>
          <div className={styles.description_mb}>문의 사항을 남겨주시면 빅밸류 담당자가 답변드립니다</div>
        </div>
        <form>
          <div className={styles.form_mobile}>
            <div className={styles.essential_mb + " " + styles.announce}>
              <div className={styles.marker_red}></div>
              <div>표시는 필수항목입니다.</div>
            </div>
            <div className={styles.form_select_item_mb}>
              <div className={styles.essential_mb + " " + styles.input_label_mb}>
                <div className={styles.marker_red}></div>
                <div>문의 종류</div>
              </div>
              <select id="selectType" name="type" className={styles.form_select_input_mb} value={params['type']} onChange={changeSelect}>
                <option value="1">솔루션 소개자료 받기</option>
                <option value="2">데이터 구매</option>
                <option value="3">기타문의</option>
              </select>
            </div>
            <div id="checkGroup_mb" className={styles.checkGroup_mb}>
              <div className={styles.cursor_pointer}>
                <input type="checkbox" value="1" onChange={checkReport}/>
                <span className={styles.pl5} onClick={(e) => {
                  changeCheck(e);
                  checkReport(e);
                }}>AI LOBIG</span>
              </div>
              <div className={styles.cursor_pointer}>
                <input type="checkbox" value="2" onChange={checkReport}/>
                <span className={styles.pl5} onClick={(e) => {
                  changeCheck(e);
                  checkReport(e);
                }}>AI Developer</span>
              </div>
              <div className={styles.cursor_pointer}>
                <input type="checkbox" value="4" onChange={checkReport}/>
                <span className={styles.pl5} onClick={(e) => {
                  changeCheck(e);
                  checkReport(e);
                }}>빌라시세닷컴</span>
              </div>
            </div>
            <div className={styles.form_item_mb}>
              <div className={styles.input_label_mb + " " + styles.no_essential_mb}>이름</div>
              <input name="contactName" type="text" className={styles.form_input_mb} placeholder="이름을 입력해주세요." onChange={onChange} ref={nameInput} autoFocus/>
            </div>
            <div className={styles.form_item_mb}>
              <div className={styles.essential_mb + " " + styles.input_label_mb}>
                <div className={styles.marker_red}></div>
                <div>이메일</div>
              </div>
              <input name="email" type="email" className={styles.form_input_mb} placeholder="이메일을 입력해주세요." onChange={onChange} ref={emailInput}/>
            </div>
            <div className={`${styles.form_item_mb}`}>
              <div className={styles.input_label_mb + " " + styles.no_essential_mb}>
                <div>전화번호</div>
              </div>
              <input name="phoneNumber" className={styles.form_input_mb} placeholder="번호를 입력해주세요." onKeyUp={changeFormPhone} ref={phoneNumberInput}/>
            </div>
            <div className={`${styles.form_item_mb}`}>
              <div className={styles.input_label_mb + " " + styles.no_essential_mb}>회사명</div>
              <input name="companyName" type="text" className={styles.form_input_mb} placeholder="회사명을 입력해주세요." onChange={onChange} ref={companyNameInput}/>
            </div>
            <div>
              <div className={styles.essential_mb + " " + styles.input_label_mb}>
                <div className={styles.marker_red}></div>
                <div>문의내용</div>
              </div>
              <div className={styles.textareaGroup_mb}>
                <textarea id="text_input_area" name="inquiry" className={styles.form_textarea_mb} rows="4" ref={inquiryInput} onKeyUp={inquiryControl}>업무 협약 등 기타 문의입니다.</textarea>
                <div className={styles.textCount_mb}>총 {count}자 / 1000자</div>
              </div>
            </div>
            <div>
              <div className={styles.essential_mb + " " + styles.input_label_mb}>
                <div className={styles.marker_red}></div>
                <div>개인정보 취급방침</div>
              </div>
              <div className={styles.privacyGroup_mb}>
                <PrivacyStatement/>
                <div className={styles.form_check_mb}>
                  <input className={styles.m0 + " " + styles.cursor_pointer} name="checkbox" type="checkbox" onChange={onChange2} ref={checkInput}/>
                  <div className={styles.cursor_pointer_mobile} onClick={(e) => {
                    changeCheck(e);
                    saveCheckParams(e)
                  }}>개인 정보 제공 동의합니다
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.contact_mb}>
              <div>
                <div className={styles.contact_head_mb}>Mail</div>
                <CopyToClipboard className={styles.contact_copy_text} text="sales@bigvalue.co.kr"
                                 onCopy={() => alert("클립보드에 복사되었습니다.")}><span>sales@bigvalue.co.kr</span></CopyToClipboard></div>
              <div>
                <div className={styles.contact_head_mb}>Tel</div>
                <CopyToClipboard className={styles.contact_copy_text} text="070.7762.9364" onCopy={() => alert("클립보드에 복사되었습니다.")}><span>070.7762.9364</span></CopyToClipboard></div>
            </div>
            <button className={styles.form_submit_mb} onClick={save}>문의하기</button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className={styles.bkgnd}>
      <div className={styles.content}>
        <div className={styles.title}>Contact us</div>
        <div className={styles.description}>문의 사항을 남겨주시면 빅밸류 담당자가 답변드립니다</div>
        <form onSubmit={(e) => {
          e.preventDefault();
        }}>
          <div className={styles.form}>
            <div className={styles.form_select_item}>
              <div className={styles.flex}>
                <div className={styles.essential + " " + styles.t4}>표시는 필수항목입니다.</div>
                <div className={styles.form_select_item}>
                  <div className={styles.form_label + " " + styles.essential + " " + styles.t17}>문의 종류</div>
                  <select id="selectType" name="type" className={styles.form_select_input} value={params['type']} onChange={changeSelect}>
                    <option value="1">솔루션 소개자료 받기</option>
                    <option value="2">데이터 구매</option>
                    <option value="3">기타문의</option>
                  </select>
                  <div id="checkGroup" className={styles.checkGroup}>
                    <div className={styles.cursor_pointer}>
                      <input type="checkbox" value="1" onChange={checkReport}/>
                      <span className={styles.pl5} onClick={(e) => {
                        changeCheck(e);
                        checkReport(e);
                      }}>AI LOBIG</span>
                    </div>
                    <div className={styles.cursor_pointer}>
                      <input type="checkbox" value="2" onChange={checkReport}/>
                      <span className={styles.pl5} onClick={(e) => {
                        changeCheck(e);
                        checkReport(e);
                      }}>AI Developer</span>
                    </div>
                    {/*<div className={styles.cursor_pointer}>*/}
                    {/*    <input type="checkbox" value="3" onChange={checkReport}/>*/}
                    {/*    <span className={styles.pl5}  onClick={(e) => {changeCheck(e); checkReport(e);}}>헬스케어</span>*/}
                    {/*</div>*/}
                    <div className={styles.cursor_pointer}>
                      <input type="checkbox" value="4" onChange={checkReport}/>
                      <span className={styles.pl5} onClick={(e) => {
                        changeCheck(e);
                        checkReport(e);
                      }}>빌라시세닷컴</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.form_item}>
              <div className={styles.form_label}>이름</div>
              <input name="contactName" type="text" className={styles.form_input} placeholder="이름을 입력해주세요." onChange={onChange} ref={nameInput} autoFocus/>
            </div>
            <div className={styles.form_item + " " + styles.essential + " " + styles.t17}>
              <div className={styles.form_label}>이메일</div>
              <input name="email" type="text" className={styles.form_input} placeholder="이메일을 입력해주세요." onChange={onChange} ref={emailInput}/>
            </div>
            <div className={styles.form_item}>
              <div className={styles.form_label}>전화번호</div>
              <input name="phoneNumber" className={styles.form_input} placeholder="번호를 입력해주세요." onKeyUp={changeFormPhone} ref={phoneNumberInput}/>
            </div>
            <div className={`${styles.form_item}`}>
              <div className={styles.form_label}>회사명</div>
              <input name="companyName" type="text" className={styles.form_input} placeholder="회사명을 입력해주세요." onChange={onChange} ref={companyNameInput}/>
            </div>
            <div className={styles.form_item + " " + styles.essential + " " + styles.t4} style={{'gridColumn': '1 / span 2'}}>
              <div className={styles.form_label}>문의내용</div>
              <div className={styles.textareaGroup}>
                <textarea id="text_input_area" name="inquiry" className={styles.form_textarea} rows="4" ref={inquiryInput} onKeyUp={inquiryControl}>업무 협약 등 기타 문의입니다.</textarea>
                <div className={styles.textCount}>총 {count}자 / 1000자</div>
              </div>
            </div>
            <div className={styles.form_item} style={{'gridColumn': '1 / span 2'}}>
              <div className={styles.form_label + " " + styles.essential + " " + styles.t4}>개인정보 취급방침</div>
              <div className={styles.privacyGroup}>
                <PrivacyStatement/>
                <div className={styles.form_check}>
                  <input className={styles.m0 + " " + styles.cursor_pointer} name="checkbox" type="checkbox" onChange={onChange2} ref={checkInput}/>
                  <div className={styles.cursor_pointer} onClick={(e) => {
                    changeCheck(e);
                    saveCheckParams(e)
                  }}>개인 정보 제공 동의합니다
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.form_bottom} style={{'gridColumn': '1 / span 2'}}>
              <div className={styles.contact}>
                <div><span className={styles.contact_head}>Mail</span><CopyToClipboard className={styles.contact_copy_text} text="sales@bigvalue.co.kr"
                                                                                       onCopy={() => alert("클립보드에 복사되었습니다.")}><span>sales@bigvalue.co.kr</span></CopyToClipboard>
                </div>
                <div><span className={styles.contact_head}>Tel</span><CopyToClipboard className={styles.contact_copy_text} text="070.7762.9364"
                                                                                      onCopy={() => alert("클립보드에 복사되었습니다.")}><span>070.7762.9364</span></CopyToClipboard></div>
              </div>
              <button className={styles.form_submit} onClick={save}>문의하기</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
